<script>
import Layout from "../../../layouts/main.vue";
import ShopCategoriesView from "@/components/product/shop-categories-view.vue";

export default {
  components: {
    ShopCategoriesView,
    Layout
  }
};
</script>

<template>
  <Layout>
    <shop-categories-view :shop-id="$store.getters['parameters/getAderloShopId']" />
  </Layout>
</template>